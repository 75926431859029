import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { has, get, isUndefined, isNull } from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { AclProvider } from '@evoja-web/client-acl';
import { Bootstrap, BootstrapContext } from '@evoja-web/react-bootstrapper';
import axios from 'axios';
import { extendMoment } from 'moment-range';
import { LoginContainer, LogoutContainer } from '@evoja-web/react-login';
import { ColumnVisibilityStorageIndexDB } from '@evoja-web/react-table';
import { CodeMapperProvider } from '@evoja-web/entity-code';
import { Provider as ModalHandlerProvider } from '@evoja-web/react-modal';
import { DataLoadPerfomanceMonitorProvider } from '@evoja-web/react-performance-monitor';
import { ApplicationRelease } from '@evoja-web/react-application-release';

import '@mdi/font/css/materialdesignicons.min.css';
import '@evoja-web/valiant-styles/dist/css/valiant.min.css';
import '@evoja-web/customer-identification/dist/bundle.esm.css';
import '@evoja-web/customer-workguide-search/dist/bundle.esm.css';
import '@evoja-web/entity-code/dist/bundle.esm.css';
import '@evoja-web/entris-portal-shared/dist/bundle.esm.css';
import '@evoja-web/gamificator/dist/bundle.esm.css';
import '@evoja-web/powersearch/dist/bundle.esm.css';
import '@evoja-web/react-ais-document-signing/dist/bundle.esm.css';
import '@evoja-web/react-bootstrapper/dist/bundle.esm.css';
import '@evoja-web/react-document-preview/dist/bundle.esm.css';
import '@evoja-web/react-form/dist/bundle.esm.css';
import '@evoja-web/react-core-textblock/dist/bundle.esm.css';
import '@evoja-web/react-file-upload/dist/bundle.esm.css';
import '@evoja-web/react-login/dist/bundle.esm.css';
import '@evoja-web/react-table/css/table.css';
import '@evoja-web/react-table/dist/bundle.esm.css';
import '@evoja-web/react-application-release/dist/bundle.esm.css';
import '@evoja-web/react-layout-components/dist/bundle.esm.css';
import './App.css';
import './packages/Workguide/WorkguideFormComponents.scss';
import './packages/General/themes/RelaxingBlue.scss';
import './packages/General/themes/RefreshingLime.scss';
import './packages/General/themes/InspiringSunset.scss';
import './packages/General/themes/BananaParty.scss';
import './packages/General/themes/OldSchool.scss';
import './packages/General/themes/ShowMoar.scss';
import 'moment/locale/de-ch';
import 'moment/locale/fr-ch';

import { IntlProvider } from './Intl';
import UseChrome from './containers/UseChrome';
import ModalContainer from './containers/Modal';
import Routes from './Routes';
import BootstrapError from './components/Bootstrap/ApplicationError';
import { Boundary as ErrorBoundary } from './packages/Error/index';
import { getStore } from './Store';
import * as guidedTourActions from './actions/GuidedTour';
import { getModalHandler, getValidator } from './globals';
import isLocalhost from './lib/Utils/isLocalhost';
import isDevSec from './lib/Utils/isDevSec';
import {
  axiosInitInterceptors,
  axiosSetDefaults
} from './lib/Axios/index';

import {
  bootstrapEnvironment,
  bootstrapIndexDb,
  bootstrapSentry
} from './sagas/Bootstrap/index';
import clientInfo from './clientInfo';
import releaseActionConfig from './ReleaseActionConfig';

const isInternetExplorer = /MSIE|Trident/.test(navigator.userAgent);

extendMoment(moment);
window.REACT_TABLE_FORCE_LOCALE = 'de-CH';
window.REACT_TABLE_VALIDATE_COLUMN_DEFINITION = isLocalhost() && !isDevSec();
window.REACT_TABLE_COLUMN_VISIBILIY_STORAGE_DEFAULT_STRATEGY = ColumnVisibilityStorageIndexDB;

const bootstrapContext = BootstrapContext()
  .initial({ id: 'sentry', saga: bootstrapSentry })
  .initial({ id: 'indexDb', saga: bootstrapIndexDb })
  .initial({ id: 'environment', saga: bootstrapEnvironment });

class App extends React.Component {
  constructor(props) {
    super(props);

    // New bootstrap implementation may lead to problems with hot reload
    // as baseUrl / token are not set on axios instance (saga does not get executed on hot reload).
    const { environment, session } = props;
    if (isLocalhost() && !isUndefined(session) && !isNull(session)) {
      axios.defaults.baseURL = get(environment, 'api_url');
      axios.defaults.headers.common['X-REST-Token'] = get(session, 'token');
    }

    const store = getStore();

    this.state = {
      userConfirmation: {
        show: null,
        message: null,
        callback: null,
        eventId: null
      },
      routes: null
    };

    this.toggleUserConfirmation = this.toggleUserConfirmation.bind(this);

    if (!isInternetExplorer) {
      const { guidedTourActions } = props;
      // Set axios defaults and init interceptors
      axiosSetDefaults();
      axiosInitInterceptors();

      // Set default locale for moment
      const locale = `${store.getState().login.language}-ch`;
      moment.locale(locale);
      window.REACT_TABLE_FORCE_LOCALE = locale;

      // Get guided tour steps
      // This should actually be done after bootstrap, but it can cause an error in joyride component under some circumstances:
      // -> Only happens after login whitout previous data from localStorage (clean local storage and login).
      // -> Not every time. Seems to be a timing problem...
      // It does not hurt, because we're working with local data (no session required),
      // but it should be fixed in dashboard container or wherever it occurs.
      guidedTourActions.getGuidedTourText();
    }
  }

  componentDidMount() {
    const { session } = this.props;

    // Same does not work in constructor...
    if (!isUndefined(session) && has(session, 'token')) {
      axios.defaults.headers.common['X-REST-Token'] = get(session, 'token');
    }
  }

  /**
   * Show a user confirmation dialog (e.g. cancel workguide)
   *
   * @param  {String}   message  Which dialog
   * @param  {Function} callback Callback
   *
   * @return void
   */
  toggleUserConfirmation(message, callback) {
    const { userConfirmation } = this.state;
    const { show } = userConfirmation;

    if (show) {
      const nextState = { show: false, message: null, callback: null };
      this.setState({ userConfirmation: nextState });
    } else {
      const nextState = { show: true, message, callback };
      this.setState({ userConfirmation: nextState });
    }
  }

  /**
   * Render router and underlying components
   *
   * @return {ReactElement} markup
   */
  renderRouter() {
    const { userConfirmation } = this.state;
    const {
      show: showUserConfirmation,
      message: userConfirmationMessage,
      callback: userConfirmationCallback
    } = userConfirmation;

    return (
      <Router getUserConfirmation={this.toggleUserConfirmation}>
        <ModalHandlerProvider handler={getModalHandler()}>
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => (
                <LoginContainer useXRestToken={false} key="login" {...props} validator={getValidator()} />
              )}
            />
            <Route
              exact
              path="/login/gateway"
              render={(props) => (
                <LoginContainer useXRestToken={isLocalhost()} key="gateway-login" {...props} strategy="gateway" validator={getValidator()} />
              )}
            />
            <Route
              exact
              path="/logout"
              render={(props) => <LogoutContainer {...props} gatewayLoginUrl={isLocalhost() ? '/login/gateway' : '/login'} />}
            />
            <Routes
              showUserConfirmation={showUserConfirmation}
              userConfirmationMessage={userConfirmationMessage}
              toggleUserConfirmation={this.toggleUserConfirmation}
              userConfirmationCallback={userConfirmationCallback}
            />
          </Switch>
          <ModalContainer handler={getModalHandler()} />
        </ModalHandlerProvider>
      </Router>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      acl,
      environment,
      language,
      session
    } = this.props;
    const { permissions, userRoles } = acl;
    const temporaryUserRoles = get(acl, 'temporaryUserRoles.data');

    if (isInternetExplorer) return <UseChrome />;

    return (
      <IntlProvider locale={`${language}-CH`}>
        <ErrorBoundary>
          <Bootstrap
            id="applicationBootstrap"
            key="applicationBootstrap"
            context={bootstrapContext}
            errorComponent={BootstrapError}
          >
            <DataLoadPerfomanceMonitorProvider namespace="map">
              <AclProvider
                permissions={get(permissions, 'data', [])}
                userRoles={get(userRoles, 'data', [])}
                session={session}
                temporaryUserRoles={temporaryUserRoles}
              >
                <CodeMapperProvider>
                  <ApplicationRelease
                    clientInfo={clientInfo}
                    environment={get(environment, 'config')}
                    releaseActionConfig={releaseActionConfig}
                  >
                    {this.renderRouter()}
                  </ApplicationRelease>
                </CodeMapperProvider>
              </AclProvider>

            </DataLoadPerfomanceMonitorProvider>
          </Bootstrap>
        </ErrorBoundary>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  acl: PropTypes.object,
  environment: PropTypes.object,
  // Used for defaultComponentDidUpdate
  // eslint-disable-next-line
  fulfilled: PropTypes.bool,
  guidedTourActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  // Used for defaultComponentDidUpdate
  // eslint-disable-next-line
  requesting: PropTypes.bool,
  session: PropTypes.object
};

App.defaultProps = {
  acl: {},
  environment: undefined,
  fulfilled: false,
  language: 'de',
  requesting: false,
  session: undefined
};

function isRequesting(state) {
  return get(state, 'bootstrap.requesting', false);
}

function isFulfilled(state) {
  return get(state, 'bootstrap.finished', false);
}

function mapStateToProps(state) {
  return {
    acl: state.acl,
    bootstrap: state.bootstrap,
    environment: state.environment,
    fulfilled: isFulfilled(state),
    language: state.login.language,
    login: state.login,
    release: state.release,
    requesting: isRequesting(state),
    session: state.login.session,
    userSwitch: state.userSwitch.selected
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidedTourActions: bindActionCreators(guidedTourActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
