import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

// import './SendByPostCreateCoverLetter.scss';
import { get, isEmpty, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';
import { PdfPreview } from '@evoja-web/react-document-preview';
import spsActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import { WorkguideCustomerAware } from '../../../Workguide';
import CreateCoverLetterForm, { getValidationDefinition } from '../../components/SendByPost/CreateCoverLetterForm';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import { BeatLoader } from '../../../General';
import ProgressSteps from '../../../General/components/MultiStepForm/ProgressSteps';

const shouldDisable = (validations) => {
  return !isEmpty(validations);
};

const STEPS = {
  CREATE_COVER_LETTER: 0,
  PREVIEW_DOCUMENT: 1
};

class CreateCoverLetter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      previewOpened: false,
      currentStep: STEPS.CREATE_COVER_LETTER
    };

    this.initForm = this.initForm.bind(this);
    this.submit = this.submit.bind(this);
    this.generateDocument = this.generateDocument.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
  }

  handleStepChange(step) {
    this.setState({ currentStep: step });
  }

  initForm() {
    const {
      spsActions,
      customer
    } = this.props;

    spsActions.createCoverLetterWorkguideFormInitRequest({
      customer
    });
  }

  /**
   * Validate the current data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { data } = this.props;
    const validator = getValidator();

    const CreateCoverLetterForValidationDef = getValidationDefinition();

    const definition = {
      validations: {
        ...CreateCoverLetterForValidationDef,
      }
    };

    return validator.validate(definition, data);
  }

  generateDocument(validations) {
    const {
      spsActions, customer, data, session
    } = this.props;

    if (!shouldDisable(validations)) {
      spsActions.createCoverLetterWorkguideFormSaveRequest({ customer, data, consultant: session });

      this.handleStepChange(STEPS.PREVIEW_DOCUMENT);
    }
  }

  submit(validations) {
    const {
      spsActions, customer, data, session, toggleRedirectModal
    } = this.props;

    if (!shouldDisable(validations)) {
      spsActions.saveArchiveCoverLetterWorkguideRequest({ customer, file: get(data, 'previewDocument'), consultant: session });
    }

    toggleRedirectModal();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customerAddresses, data, fulfilled, spsActions, language, onFormCancel, intl, fileGenerating, fileError
    } = this.props;

    const { currentStep } = this.state;

    const validationResult = this.validate();

    const steps = [
      {
        title: intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.CreateCoverLetter' }),
        component: (
          <>
            <SectionTitle>
              <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.CreateCoverLetter" />
            </SectionTitle>
            <CreateCoverLetterForm
              customerAddresses={customerAddresses}
              data={data}
              fulfilled={fulfilled}
              language={language}
              showValidations
              validations={validationResult}
              onChange={spsActions.createCoverLetterWorkguideFormSetValue}
              withoutReplyLetter
            />
          </>
        )
      },
      {
        title: intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.Preview' }),
        component: fileGenerating ? (
          <Alert type="info">
            <AlertContent>
              <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Document.Generating" />
              <BeatLoader />
            </AlertContent>
          </Alert>
        ) : (
          <PdfPreview
            blob={get(data, 'previewDocument')}
            errorDescription={intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.Document.Error.Description' })}
            errorTitle={intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.Document.Error.Title' })}
          />
        )
      },
    ];

    return (
      <WorkguideCustomerAware
        onChange={this.initForm}
        onInit={this.initForm}
        requesting={!fulfilled}
        showLoadIndicator
      >
        <Section>
          <ProgressSteps
            steps={steps}
            currentStep={currentStep}
            onStepChange={this.handleStepChange}
            allowStepClick={isEmpty(this.validate())}
            showNavigationButtons={false}
          />
          <SectionContent>
            <div className="workguide-form--buttons">
              {/* Cancel Button */}
              <Button
                color="primary"
                onClick={onFormCancel}
                style={{ marginLeft: '20px' }}
              >
                <FormattedMessage id="Sps.CreateCoverLetterForm.Close" />
              </Button>

              {/* Generate Cover Letter Button */}
              {currentStep === STEPS.CREATE_COVER_LETTER && (
                <Button color="primary" disabled={!isEmpty(validationResult)} onClick={() => this.generateDocument(validationResult)}>
                  <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Steps.Preview" />
                </Button>
              )}

              {/* Save generated Cover Letter Button */}
              {currentStep === STEPS.PREVIEW_DOCUMENT && (
                <Button
                  color="primary"
                  disabled={!isEmpty(validationResult) && !isEmpty(get(fileError, 'error'))}
                  onClick={() => this.submit(validationResult)}
                >
                  <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Steps.Create" />
                </Button>
              )}

              {/* Go back Button */}
              {currentStep > 0 && (
                <Button outline color="primary" onClick={() => this.handleStepChange(currentStep - 1)}>
                  <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Steps.PrevStep" />
                </Button>
              )}
            </div>
          </SectionContent>
        </Section>
      </WorkguideCustomerAware>
    );
  }
}

CreateCoverLetter.propTypes = {
  spsActions: PropTypes.object.isRequired,
  customer: PropTypes.object,
  customerAddresses: PropTypes.array,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object,
  fulfilled: PropTypes.bool,
  language: PropTypes.string,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  onFormCancel: PropTypes.func,
  fileGenerating: PropTypes.bool,
  fileError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
};

CreateCoverLetter.defaultProps = {
  customer: undefined,
  customerAddresses: [],
  data: {},
  fileGenerating: true,
  fileError: false,
  language: 'de',
  fulfilled: false,
  toggleRedirectModal: noop,
  onFormCancel: noop
};

function isFulfilled(state) {
  return (
    get(state, 'address.customerAddresses.fulfilled', false)
    || get(state, 'address.createCoverLetterWorkguideForm.initialized', false)
  );
}

function mapStateToProps(state, ownProps) {
  const customer = get(ownProps, 'customer');

  return {
    customer,
    data: get(state, 'sps.createCoverLetterWorkguideForm.data'),
    fileGenerating: get(state, 'sps.createCoverLetterWorkguideForm.saving'),
    fileError: get(state, 'sps.createCoverLetterWorkguideForm.error'),
    fulfilled: isFulfilled(state),
    customerAddresses: get(state, 'address.customerAddresses.data'),
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    spsActions: bindActionCreators(spsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateCoverLetter));
