import { takeEvery, takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import deleteForeignAsset from './ForeignAsset/deleteForeignAsset';
import deletePaymentRule from './PaymentRule/deletePaymentRule';
import getAntimoneyLaundering from './AntimoneyLaundering/getAntimoneyLaundering';
import getForeignAssets from './ForeignAsset/getForeignAssets';
import getIncomes from './Income/getIncomes';
import getSalaries from './Salary/getSalaries';
import getKpi from './Kpi/getKpi';
import getKpiHistory from './Kpi/getKpiHistory';
import getKubiRatio from './Kpi/getKubiRatio';
import getPaymentRules from './PaymentRule/getPaymentRules';
import getProfileCompletionCompany from './ProfileCompletion/getCompany';
import getPortfolios from './Portfolio/getPortfolios';
import getProvisionNarilo from './Portfolio/fetchProvisionNarilo';
import getRatings from './Rating/getRatings';
import getSuspensionSummary from './Suspension/getSummary';
import getTariffRules from './Tariff/getTariffRules';

import getAccounts from './SetAdvisor/getAccounts';
import getContracts from './SetAdvisor/getContracts';
import getCustomerBalance from './SetAdvisor/getCustomerBalance';
import getPricing from './SetAdvisor/getPricing';
import getProductbundleBundle from './SetAdvisor/getProductbundleBundle';
import getProductbundleProduct from './SetAdvisor/getProductbundleProduct';
import getProductbundleUsage from './SetAdvisor/getProductbundleUsage';
import getProductbundleUsageDetail from './SetAdvisor/getProductBundleUsageDetail';
import getTextblocks from './SetAdvisor/getTextblocks';
import getAdvertising from './Advertising/getAdvertising';

import customerDetailActions from '../actions/Actions';
import duplicatesCheck from './DuplicatesCheck/duplicatesCheck';
import { actions as interestActions } from '../../Interest/index';
import getKpiFinancingHistory from './Kpi/getFinancingHistory';

const {
  ANTIMONEY_LAUNDERING_REQUEST,
  FOREIGN_ASSET_DELETE_FULFILLED,
  FOREIGN_ASSET_DELETE_REQUEST,
  FOREIGN_ASSETS_REQUEST,
  INCOMES_REQUEST,
  SALARIES_REQUEST,
  KPI_REQUEST,
  KPI_HISTORY_REQUEST,
  KPI_FINANCING_HISTORY_REQUEST,
  KUBI_RATIO_REQUEST,
  PAYMENT_RULE_DELETE_REQUEST,
  PAYMENT_RULES_REQUEST,
  PORTFOLIOS_REQUEST,
  PROFILE_COMPLETION_COMPANY_REQUEST,
  RATINGS_REQUEST,
  SUSPENSION_SUMMARY_REQUEST,
  TARIFF_RULES_REQUEST,

  ACCOUNTS_REQUEST,
  CONTRACTS_REQUEST,
  CUSTOMER_BALANCE_REQUEST,
  PRICING_REQUEST,
  PRODUCTBUNDLE_BUNDLE_REQUEST,
  PRODUCTBUNDLE_PRODUCT_REQUEST,
  PRODUCTBUNDLE_USAGE_REQUEST,
  PRODUCTBUNDLE_USAGE_DETAIL_REQUEST,
  TEXTBLOCKS_REQUEST,
  GET_PROVISION_NARILO_REQUEST,
  CUSTOMER_ADVERTISING_REQUEST,
  CUSTOMER_DETAIL_DUPLICATES_CHECK_REQUEST
} = customerDetailActions;

const {
  DELETE_INTEREST_FULFILLED,
  SAVE_INTEREST_FULFILLED
} = interestActions;

export default function* customerDetailRootSaga() {
  yield takeEvery(FOREIGN_ASSET_DELETE_REQUEST, deleteForeignAsset);
  yield takeEvery(PAYMENT_RULE_DELETE_REQUEST, deletePaymentRule);
  yield takeLatest(ANTIMONEY_LAUNDERING_REQUEST, getAntimoneyLaundering);
  yield takeLatest(FOREIGN_ASSETS_REQUEST, getForeignAssets);
  yield takeLatestByDataKey(INCOMES_REQUEST, getIncomes);
  yield takeLatestByDataKey(SALARIES_REQUEST, getSalaries);
  yield takeLatest(KPI_REQUEST, getKpi);

  yield takeLatest(KPI_FINANCING_HISTORY_REQUEST, getKpiFinancingHistory);
  yield takeLatest(KPI_HISTORY_REQUEST, getKpiHistory);
  yield takeLatest(KUBI_RATIO_REQUEST, getKubiRatio);
  yield takeLatest(PAYMENT_RULES_REQUEST, getPaymentRules);
  yield takeLatest(PORTFOLIOS_REQUEST, getPortfolios);
  yield takeLatest(PROFILE_COMPLETION_COMPANY_REQUEST, getProfileCompletionCompany);
  yield takeLatest(RATINGS_REQUEST, getRatings);
  yield takeLatest(SUSPENSION_SUMMARY_REQUEST, getSuspensionSummary);
  yield takeLatest(TARIFF_RULES_REQUEST, getTariffRules);
  yield takeLatest(CUSTOMER_DETAIL_DUPLICATES_CHECK_REQUEST, duplicatesCheck);

  // SetAdvisor
  yield takeLatest(ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(CUSTOMER_BALANCE_REQUEST, getCustomerBalance);
  yield takeLatest(CONTRACTS_REQUEST, getContracts);
  yield takeLatest(PRODUCTBUNDLE_BUNDLE_REQUEST, getProductbundleBundle);
  yield takeLatest(PRODUCTBUNDLE_PRODUCT_REQUEST, getProductbundleProduct);
  yield takeLatest(PRODUCTBUNDLE_USAGE_REQUEST, getProductbundleUsage);
  yield takeLatest(TEXTBLOCKS_REQUEST, getTextblocks);
  yield takeLatest(PRICING_REQUEST, getPricing);
  yield takeLatest(PRODUCTBUNDLE_USAGE_DETAIL_REQUEST, getProductbundleUsageDetail);

  // Listen to other actions
  yield takeLatest(FOREIGN_ASSET_DELETE_FULFILLED, getProfileCompletionCompany);
  yield takeLatest(DELETE_INTEREST_FULFILLED, getProfileCompletionCompany);
  yield takeLatest(SAVE_INTEREST_FULFILLED, getProfileCompletionCompany);
  yield takeLatest(GET_PROVISION_NARILO_REQUEST, getProvisionNarilo);
  yield takeLatest(CUSTOMER_ADVERTISING_REQUEST, getAdvertising);
}
