import { put, call } from 'redux-saga/effects';
import { get } from 'lodash';

import { archive, upload } from '@evoja-web/react-file-upload/sagas';
import { GravitonFile } from '@evoja-web/react-file-upload';
import actions from '../../actions/Actions';
import createDocument from '../../../PrintDocumentPreview/sagas/createDocument';
import printDocumentPreviewActions from '../../../PrintDocumentPreview/actions/Actions';

const {
  SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_PENDING,
  SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_FULFILLED,
  SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_REJECTED
} = actions;

const { DOCUMENTS_REQUEST } = printDocumentPreviewActions;

function* uploadAndArchiveFile({ fileToUpload, customer }) {
  // Upload generated Files
  const file = GravitonFile();
  file.fromFile(fileToUpload);

  const additionalProperties = file.getAdditionalProperties();
  const links = file.getLinks();
  const fileId = file.getId();

  additionalProperties.replace('formNr', '100800');
  additionalProperties.replace('searchValue', '9');
  additionalProperties.replace('customerNumber', get(customer, 'customerNumber'));
  links.replace('owner', `http://api.vcap.me/person/customer/${get(customer, 'id')}`);

  yield call(upload, { file });

  const { file: archivedFile } = yield call(archive, { dataKey: fileId, customer, file });

  return archivedFile;
}

export default function* saveAndArchiveCoverLetter(request) {
  const { file, customer, consultant } = request;

  yield put({ type: SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_PENDING });

  try {
    const uploadedFile = yield call(uploadAndArchiveFile, { fileToUpload: file, customer });

    const documentData = {
      callingProcessKeyDE: 'MAP Workguide',
      callingProcessKeyFR: 'PTC Workguide',
      docLink: `/file/${uploadedFile.getId()}`,
      docNameDE: 'Begleitschreiben',
      docNameFR: "Lettre d'accompagnement",
      documentStatusDe: 'Bereit zum Versand',
      documentStatusFr: 'Prêt à être envoyé',
      noDigitalSigning: true
    };

    yield call(createDocument, { data: documentData, session: consultant, customerId: get(customer, 'id') });

    yield put({ type: DOCUMENTS_REQUEST, dataKey: get(customer, 'id') });

    const payload = { fileId: uploadedFile.getId() };

    yield put({ type: SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_REJECTED, error });

    return error;
  }
}
